.privacy {
  font-family: "Akkurat", Helvetica, Arial, sans-serif;
}
.title,
.subtitle {
  font-family: "Akkurat", Helvetica, Arial, sans-serif;
  font-weight: 300;
}
.scroller {
  border: 1px solid black;
  padding: 16px 32px;
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 7px;
  background-color: white;
}
.scroller.short {
  border-top-left-radius: 0;
  max-height: calc(50vh - 51px);
}
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.accept {
  height: 60px;
  width: 260px;
  background-color: white;
}
.logout {
  height: 40px;
  width: 175px;
  background-color: #001E33;
  color: white;
}
.accept,
.logout {
  transition: background-color 600ms;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid #001E33;
  margin: 1em;  
}
.accept:hover {
  background-color: #e1f8ff;
}
.none {
  font-style: italic;
}
.tabs {
  font-family: "Akkurat Mono", monospace;
  display: flex;
}
.tab {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  border: 1px solid;
  border-bottom: 0;
  margin-bottom: -1px;
  background-color: white;
  z-index: 1;
}
.tab.first {
  margin-left: 0;
}
.tab.inactive {
  background-color: #F0F1F4;
  border-bottom: 1px solid;
}
.tab.active {
  background-color: white;
}
.versionNumber {
  font-family: "Akkurat Mono", monospace;
  text-transform: uppercase;
  color: #677796;
}
.back {
  color:#677796;
  line-height: 3;
}

@media screen and (max-width: 600px) {
  .buttons {
    flex-direction: column-reverse;
  }
  .scroller {
    border-top-right-radius: 0;
    padding: 0px 12px;
  }
  .scroller.short {
    max-height: calc(50vh - 46px);
  }
  .accept {
    margin-bottom: 0;
  }
  .tab {
    width: 49%;
    margin-right: 0;
    margin-left: 0;
    text-align: center;
    font-size: 11px;
  }
  .tabs {
    justify-content: space-between;
  }
  .privacy {
    padding: 20px;
    margin-top: -70px;
  }
  .title {
    font-size: 24px;
  }
}
